<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >


        <v-col cols="6">
          Sur la base des facteurs d'émissions mis à jour en {{ mois_maj_fe }}
        </v-col>
        <v-spacer class="hidden-sm-and-down" />
        <v-col
          cols="6"
          md="auto"
        >
          <div class="text-body-1 pt-6 pt-md-0 text-center">
            &copy; UPE 2022 -
            <v-dialog
              v-model="dialog_legal_mentions"
              width="1000"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  id="more-information-button"
                  @click="dialog_legal_mentions = true"
                  >
                  Mentions légales
                </span>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  <h2 class="mb-4 font-weight-bold">Mentions légales</h2>
                </v-card-title>

                <v-card-text id="methodology-card">
                  <h5 class="float-right">Dernière mise à jour : Février 2022</h5>
                  <h3>Éditeur</h3>
                  Le site accessible à l’adresse http://carbone-calculateur-adoohcc.upe.fr est édité par l’Union de la Publicité Extérieure.<br/>
                  <br/>
                  Siège social : 2 rue Sainte-Lucie – 75015 Paris<br/>
                  Contact : contact@upe.fr<br/>
                  <br/>
                  <h3>Directeur de publication</h3>
                  Stéphane DOTTELONDE<br/>
                  <br/>
                  <h3>Conception et réalisation de l’outil</h3>
                  Cet outil a été conçu et réalisé par la société Axionable :<br/>
                  <br/>
                  Siège : 13 rue des Arquebusiers – 75003 Paris <br/>
                  <br/>
                  Pour toute question sur l’outil, vous pouvez contacter la société Axionable à l’adresse mail suivante :<br/>
                  <br/>
                  quentin.faulconnier@axionable.com<br/>
                  <br/>
                  <h3>Limitation de responsabilité</h3>
                  L’Union de la Publicité Extérieure s'efforce d'assurer au mieux de ses possibilités, l'exactitude et la mise à jour des informations diffusées sur le Site, dont elle se réserve le droit de corriger, à tout moment et sans préavis, le contenu. Toutefois, l’Union de la Publicité Extérieure ne peut garantir l'exactitude, la précision ou l'exhaustivité des informations mises à la disposition sur le Site.<br/>
                  <br/>
                  En conséquence, Union de la Publicité Extérieure décline toute responsabilité :<br/>
                  <ul>
                    <li>pour toute interruption du Site ;</li>
                    <li>pour toute survenance de bogues ;</li>
                    <li>pour toute inexactitude ou omission portant sur des informations disponibles sur le Site ;</li>
                    <li>pour tous dommages résultant d'une intrusion frauduleuse d'un tiers ayant entraîné une modification des informations mises à la disposition sur le Site…</li>
                  </ul>
                  <i>Cette liste n’est pas exhaustive.<br/></i>
                  <br/>
                  L’internaute s’engage à respecter les présentes mentions légales et à utiliser le Site sous sa responsabilité. L’internaute est réputé avoir accepté la dernière version des présentes mentions du simple fait de la navigation sur le Site.<br/>
                  <br/>
                  <h3>Propriété intellectuelle</h3>
                  Le Site ainsi que tout logiciel utilisé nécessairement en relation avec celui-ci peuvent contenir des informations confidentielles et protégées par le droit de propriété intellectuelle en vigueur ou toute autre loi.<br/>
                  <br/>
                  La reproduction de tout ou partie du contenu publié sur le Site est seulement autorisée aux fins exclusives d'information pour un usage personnel et privé, toute reproduction et toute utilisation de copies réalisées à d'autres fins étant expressément interdites. Il est également interdit de copier, modifier, créer une œuvre dérivée, inverser la conception ou l'assemblage ou de toute autre manière tenter de trouver le code source (à l'exception des cas prévus par la loi), vendre, attribuer, sous-licencier ou transférer de quelque manière que ce soit tout droit afférent au Site. De même, il est également interdit de modifier le Site ou d'utiliser de versions modifiées du Site et notamment (sans que cette énumération soit limitative) en vue d'obtenir un accès non autorisé et d'accéder au Site par un autre moyen que par l'interface qui est fournie à l’internaute par l’Union de la Publicité Extérieure à cet effet.<br/>
                  <br/>
                  <h3>Liens vers le calculateur</h3>
                  Toute mise en place d’un lien hypertexte vers le Site doit faire l’objet d’une demande préalable auprès de l’Union de la Publicité Extérieure à l’adresse mail suivante : contact@upe.fr<br/>
                  <br/>
                  <h3>Données personnelles</h3>
                  L’Union de la Publicité Extérieure accorde une grande importance à la protection des données à caractère personnel et au respect de la législation en vigueur, en particulier le Règlement général sur la protection des données (RGPD).<br/>
                  <br/>
                  Pour plus d’informations, nous vous invitons à consulter notre politique de confidentialité à l’adresse suivante :<br/>
                  <br/>
                  <a href="https://www.upe.fr/?rub=mentions">https://www.upe.fr/?rub=mentions</a><br/>
                  <br/>
                  <h3>Hébergeur</h3>
                  Le Site est hébergé par la société Google France.<br/>
                  <br/>
                  Siège : 8 rue de Londres – 75009 Paris<br/>
                  <br/>
                  <h3>Droit applicable</h3>
                  Le Site ainsi que les présentes mentions sont régis par le droit français.<br/>
                  En cas de contestation ou de litige relatif à l’interprétation ou à l’exécution des présentes mentions, les tribunaux de Paris seront seuls compétents.<br/>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog_legal_mentions = false"
                  >
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

  import calculator_mixin from "@/views/mixins/calculator_mixin";

  export default {
    name: 'DashboardCoreFooter',
    mixins: [calculator_mixin],
    data: () => ({
      mois_maj_fe:"...",
      dialog_legal_mentions: false,
      links: [
        {
          href: '#',
          text: 'Mentions légales',
        },
      ],
    }),
    mounted() {
      this.get_mois_maj_acv().then((res) => {
        this.mois_maj_fe = res;
    })

    },
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    margin: 12px
    margin-top: 0
    padding-bottom: 0
    a
      font-size: .825rem
      font-weight: 400 !important
      text-decoration: none
      text-transform: uppercase
      color: initial !important
</style>
